import { Component, Inject, OnInit, PLATFORM_ID, ViewChild, inject } from '@angular/core';
import { AsyncPipe, isPlatformBrowser, NgClass } from '@angular/common';
import { NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

import { MaterialModule } from './shared/material.module';
import { Observable, Subscription, map, shareReplay } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { UserService } from './services/user.service';
import { LoginDialogService } from './services/login-dialog.service';
import { StorageService } from './services/storage.service';
import { MatDrawer } from '@angular/material/sidenav';
import { ResponsiveService } from './services/responsive.service';
import { FooterComponent } from './core/components/footer/footer.component';
import { HeaderComponent } from './core/components/header/header.component';

@Component({
  selector: 'app-home',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [
    RouterOutlet,
    NgClass,
    AsyncPipe,
    RouterLink,
    RouterLinkActive,
    MaterialModule,
    HeaderComponent,
    FooterComponent,
  ],
})
export class AppComponent implements OnInit {

  opened: boolean = false;

  constructor(
    public userService: UserService,
  ) { }
  ngOnInit(): void {
 
  }

  toggleHandle() {
    this.opened = !this.opened
  }
}
