import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'app/services/storage.service';
import { SnackbarService } from 'app/services/snackbar.service';
import { finalize, tap } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const storageService = inject(StorageService);
  const router = inject(Router);
  const snack = inject(SnackbarService);

  const started = Date.now();
  const token = storageService.getToken();

  const authReq = token ? req.clone({
    setHeaders: {
      'Content-Type': 'application/json',
      'token': `${token}`,
    },
  }) : req;

  return next(authReq).pipe(
    tap({
      error: (err) => {
        if (err.error.code === 11111) {
          snack.open(err.error.message);
          router.navigate(['/login']);
        }
      }
    }),
    finalize(() => {
      // const elapsed = Date.now() - started;
      // console.log(`${authReq.method} "${authReq.urlWithParams}" in ${elapsed} ms.`);
    })
  );
};