import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LoginDialogService } from 'app/services/login-dialog.service';

import { StorageService } from 'app/services/storage.service';

export const authGuard: CanActivateFn = (route, state) => {
  // console.log("AuthGuard start");

  const storageService = inject(StorageService)
  const router = inject(Router)
  const loginDialog = inject(LoginDialogService)

  const token = storageService.getToken()

  if (!!token) {
    // 向服务器发送验证请求，并根据服务器的响应决定是否允许导航
    // 如果 token 有效，则返回 true；否则返回 false
    // 这里需要实现向服务器验证 token 的逻辑
    // 示例代码：
    // return this.authService.validateToken(token).pipe(
    //    map(valid => valid),
    //    catchError(error => false)
    // );
    // 假设 validateToken 返回一个 Observable<boolean>，用来表示 token 是否有效
    return true
  }
  // 如果没有 token 或 token 无效，则禁止导航
   router.navigate(['/'])
  
  return false
};
