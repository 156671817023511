<app-header (toggle)="toggleHandle()"></app-header>

<mat-drawer-container class="content">
  <mat-drawer #drawer [opened]="opened" mode="over">
    <div class="sidenav-container">
      <div class="sidenav-content">
        <mat-nav-list>
          <a mat-list-item routerLink="/movie" (click)="toggleHandle()">
            <mat-icon matListItemIcon>movie</mat-icon>
            <span matListItemTitle>电影</span>
          </a>
          <a mat-list-item routerLink="/tv" (click)="toggleHandle()">
            <mat-icon matListItemIcon>tv</mat-icon>
            <span matListItemTitle>电视剧</span>
          </a>
          <a mat-list-item routerLink="/collection" (click)="toggleHandle()">
            <mat-icon matListItemIcon>collections</mat-icon>
            <span matListItemTitle>专辑系列</span>
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/search" (click)="toggleHandle()">
            <mat-icon matListItemIcon>search</mat-icon>
            <span matListItemTitle>搜索</span>
          </a>
        </mat-nav-list>
      </div>
      <span class="copyright">© 2024 The Movie Find</span>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
<app-footer></app-footer>