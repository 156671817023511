import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { themeColors } from '../../constants/theme-colors';
import { Color } from '../../enums/colors.enum';
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { AsyncPipe, NgForOf, NgOptimizedImage } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { UserService } from 'app/services/user.service';
import { MatDividerModule } from '@angular/material/divider';
import { LoginDialogService } from 'app/services/login-dialog.service';
import { Subscription } from 'rxjs';
import { StorageService } from 'app/services/storage.service';
import { MaterialModule } from 'app/shared/material.module';
import { ResponsiveService } from 'app/services/responsive.service';
import { SnackbarService } from 'app/services/snackbar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    RouterLinkActive,
    NgOptimizedImage,
    RouterLink,
    NgForOf,
    AsyncPipe,
    MaterialModule,
  ],
  standalone: true
})
export class HeaderComponent implements OnInit{
  username: string = "";
  avatarUrl: string = "";
  isLoggedIn: boolean = false;
  subscription!: Subscription;
  private avatarSubscription: Subscription;

  @Output() changeColorTheme: EventEmitter<string> = new EventEmitter();

  themeColorList = themeColors;
  themeColorInit: string = Color.RED;
  @Output() toggle: EventEmitter<boolean> = new EventEmitter();

  isScrolled = false;

  @HostListener('window:scroll')
  scrollEvent() {
    this.isScrolled = window.scrollY >= 30;
  }

  constructor(
    public userService: UserService,
    private loginDialogService: LoginDialogService,
    private storageService: StorageService,
    public responsiveService: ResponsiveService,
    private snackService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = this.storageService?.readJsonData(this.userService.IS_LOGGED_IN_KEY) || false;
    this.username = this.storageService?.readJsonData("username");
    this.avatarUrl = this.storageService?.readJsonData("avatar");
    // 订阅登陆状态
    this.subscription = this.userService.isLoggedIn$.subscribe({
      next: (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn
      }
    })
    // 订阅头像
    this.avatarSubscription = this.userService.avatar$.subscribe(avatar => {
      this.avatarUrl = avatar;
    });
  }

  handleLogin() {
    this.loginDialogService.openLoginDialog()
  }
  
  handleLogout() {
    this.isLoggedIn = false
    this.storageService.saveJsonData(this.userService.IS_LOGGED_IN_KEY, this.isLoggedIn)
    this.userService.clearLoginStatus()
    this.storageService.clearAllData()
    this.snackService.open("退出成功")
  }

  drawerToggle() {
    this.toggle.emit()
  }

  ngOnDestroy(): void {
    // if (this.avatarSubscription) {
    //   this.avatarSubscription.unsubscribe();
    // }    
  }
}
