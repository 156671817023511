<footer class="footer">
  <!-- <div>Developed by <span class="footer__janco">JancoBH</span></div>
  <a *ngFor="let social of socialData" [href]="social.url"
     target="_blank" rel="noopener noreferrer"
     [attr.aria-label]="'Janco Boscan ' + social.name"
     [title]="social.name"
     class="footer__social"
  >
    <img [src]="social.img" [alt]="'Angular Movies '  + social.name" loading="lazy">
  </a> -->
  <div class="copy_right">电影网提供的百度云网盘,阿里网盘,夸克网盘资源均来自用户和网友分享，并不提供影片资源存储、录制、
    上传，若本站收录的资源无意侵犯了贵司版权，请在第一时间联系我们，我们将在72小时内删除侵权内容！邮箱：bomovie★gmail.com Copyright © 2023 All Rights
    Reserved.
</div>
</footer>
